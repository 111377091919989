import { channeltree } from '@/api/marketing/channel'
import { getLabelPage } from '@/api/crmManagement/setRules'
const enumAll = {
  state: {
    // 任务状态
    planStatus: [
      {
        label: '全部',
        value: 0
      },
      {
        label: '新任务',
        value: 1
      },
      {
        label: '已安排',
        value: 2
      },
      {
        label: '已发运',
        value: 3
      },
      {
        label: '已完成',
        value: 4
      },
      {
        label: '中途卸车',
        value: 5
      },
      {
        label: '中途发车',
        value: 7
      },
      {
        label: '已取消',
        value: 6
      }
    ],
    // 小车车辆状态
    vehicleStatus: [
      {
        label: '全部',
        value: 0
      },
      {
        label: '待安排',
        value: 1
      },
      {
        label: '已安排',
        value: 2
      },
      {
        label: '已发运',
        value: 3
      },
      {
        label: '中转待派',
        value: 4
      },
      {
        label: '已完成',
        value: 9
      }
    ],
    //待删除
    smallVehStatus: [
      {
        label: '全部',
        value: 0
      },
      {
        label: '未安排',
        value: 1
      },
      {
        label: '已装车',
        value: 2
      },
      {
        label: '已发运',
        value: 3
      },
      {
        label: '已完成',
        value: 4
      },
      {
        label: '中途卸车',
        value: 5
      }
    ],
    // 内容查询
    contentType: [
      {
        label: '操作',
        value: 1
      },
      {
        label: '聊天',
        value: 2
      },
      {
        label: '推送',
        value: 3
      },
      {
        label: '计划',
        value: 4
      }
    ],
    // 当天 本周 本月 本年
    date: [
      {
        label: '今天',
        value: 1
      },
      {
        label: '本周',
        value: 2
      },
      {
        label: '本月',
        value: 3
      }, {
        label: '本年',
        value: 4
      },
      {
        label: '自定义选择',
        value: 5
      }
    ],
    // 单纯的是否
    BooleanFlagEnum: [
      {
        label: '是',
        value: 1
      },
      {
        label: '否',
        value: 0
      }],
    // 是否有效
    effectiveEnum: [
      {
        label: '是',
        value: 1
      },
      {
        label: '否',
        value: 0
      }
    ],
    // 线索
    ClueTypeEnum: [
      {
        label: '全部',
        value: 0
      },
      {
        label: '主叫线索',
        value: 1
      },
      {
        label: '表单线索',
        value: 2
      },
      {
        label: '在线线索',
        value: 3
      },
      {
        label: '小程序',
        value: 4
      }
    ],
    // 拉黑
    blacklist: [
      {
        label: '拉黑客户',
        value: 1
      },
      {
        label: '取消拉黑',
        value: 0
      }
    ],
    // 客户业务类型
    CustomerBusinessTypeEnum: [
      {
        label: '全部',
        value: ''
      },
      {
        label: '个人散单',
        value: 1
      },
      {
        label: '同行业务',
        value: 2
      },
      {
        label: '大客户业务',
        value: 3
      },
      {
        label: '资源业务',
        value: 4
      }
    ],
    // 客户类型
    CustomerTypeEnum: [
      {
        label: '全部',
        value: ''
      },
      {
        label: '个人',
        value: 1
      },
      {
        label: '企业',
        value: 2
      }
    ],
    // 客户分类类型
    CustomerClassEnum: [
      {
        label: '全部',
        value: ''
      },
      {
        label: '临时客户',
        value: -1
      },
      {
        label: '无效客户',
        value: 0
      },
      {
        label: '公海客户',
        value: 1
      },
      {
        label: '私海客户',
        value: 2
      },
      {
        label: '合作客户',
        value: 3
      }
    ],
    // 单纯的是否
    DocumentaryEnum: [
      {
        label: '已读',
        value: 1
      },
      {
        label: '未读',
        value: 0
      },
      {
        label: '完结',
        value: 9
      }],
    // 订单结算方式
    CustomerSettlementEnum: [
      {
        label: '全部',
        value: 0
      },
      {
        label: '实时',
        value: 1
      },
      {
        label: '月结',
        value: 2
      },
      {
        label: '记账',
        value: 3
      }
    ],
    // 客户来源
    customerSource: [
      {
        label: '主动开发',
        value: 0
      },
      {
        label: '公司介绍',
        value: 1
      },
      {
        label: '内部介绍',
        value: 2
      },
      {
        label: '客户介绍',
        value: 3
      },
      {
        label: '网络引流',
        value: 4
      },
      {
        label: '物流同行',
        value: 5
      },
    ],
    // 渠道分类
    ChannelTypeEnum: [],
    // 客户标签 搜索
    CustomerLabelType: [],
    // 客户标签  实用
    CustomerType: [],
    // 无效线索
    InvalidClue: [],
    // 退回标签
    DropPublicEnum: [],
    // 意向标签
    IntentionEnum: [],
    // 跟进标签
    FollowUpEnum: [],
    //  承运商标签
    CarrierEnum: [],
    // 线路标签
    Label: [],
    // 跟进方式
    FollowTypeEnum: [{
      label: '电话',
      value: 1
    },
    {
      label: '微信',
      value: 2
    },
    {
      label: '上门拜访',
      value: 3
    }],
    // 承运商类型
    carrierType: [{
      label: '内部',
      value: 1
    }, {
      label: '外部',
      value: 2
    }],
    // 运力属性
    Attribute: [{
      label: '信息部',
      value: 1
    }, {
      label: '专线',
      value: 2
    }, {
      label: '小板',
      value: 3
    }, {
      label: '地跑',
      value: 4
    }, {
      label: '商品车队',
      value: 5
    }],

    // 业务类型
    businessType: [{
      label: '干线',
      value: 1
    }, {
      label: '省内',
      value: 2
    }, {
      label: '同城',
      value: 3
    }],
    // 线路类型
    routeType: [{
      label: '点-点',
      value: 1
    }, {
      label: '点-店',
      value: 2
    }, {
      label: '店-点',
      value: 3
    }, {
      label: '店-店',
      value: 4
    }],
    // 报价类型
    lineOfferType: [{
      label: '一口价',
      value: 1
    }, {
      label: '公式价',
      value: 2
    }],
    // 车辆类型
    truckType: [{
      label: '大板车',
      value: 1
    }, {
      label: '小板车',
      value: 2
    }, {
      label: '货车',
      value: 3
    }],
    nweTruckType: [{
      label: '大板车',
      value: 1
    }, {
      label: '小板车',
      value: 2
    }],
    // 运输类型
    transportType: [{
      value: 0,
      label: '地跑'
    }, {
      value: 1,
      label: '小板'
    }],
    // GPS类型
    gpsType: [{
      label: '智运GPS',
      value: 1
    }, {
      label: '超越GPS',
      value: 2
    }, {
      label: '关闭GPS',
      value: 3
    }, {
      label: '途强GPS',
      value: 4
    }],
    // 线路价格类型
    LinePriceType: [{
      label: '上浮',
      value: 1
    }, {
      label: '下浮',
      value: 0
    }],
    // 报价类型
    offerTypeEnum: [
      {
        label: '全部',
        value: ''
      },
      {
        label: 'B端报价',
        value: 2
      },
      {
        label: '运力报价',
        value: 1
      }
    ],
    // 优惠券类型
    couponTypeEnum: [],
    // 通联 创建会员
    membersTypeEnum: [
      {
        label: '个人',
        value: 3
      },
      {
        label: '企业',
        value: 2
      }
    ],
    payTypeEnum: [
      {
        label: '微信扫码支付',
        value: 1
      },
      {
        label: '支付宝扫码支付',
        value: 2
      },
      {
        label: '聚合扫码',
        value: 12
      },
      {
        label: '收银宝微信小程序',
        value: 13
      }
    ],
    financeTypeEnum: [
      {
        label: '承运商收款结算流程',
        value: 0
      },
      {
        label: '承运商付款结算流程',
        value: 1
      },
      {
        label: '客户结算流程',
        value: 2
      },
      {
        label: '返款流程',
        value: 3
      },
      {
        label: '附加费流程',
        value: 4
      },
      {
        label: '质损流程',
        value: 5
      }
    ],
    // 生成方式
    payMethodEnum: [
      {
        label: '微信扫码',
        value: 1
      },
      {
        label: '支付宝扫码',
        value: 2
      },
      {
        label: '聚合扫码',
        value: 12
      },
      {
        label: '微信小程序',
        value: 13
      },
    ],
    // 付款状态
    paymentStatusEnum: [
      {
        label: '未支付',
        value: 1
      },
      {
        label: '交易成功',
        value: 4
      }
    ],
    // 推广类型
    extensionTypeEnum: [
      {
        label: '内部员工',
        value: 1
      },
      {
        label: '外部地推',
        value: 2
      },
      {
        label: '小程序',
        value: 3
      },
      {
        label: '渠道方',
        value: 4
      }
    ],
    orderStatus: [
      {
        value: 0,
        label: '保存'
      },
      {
        value: 11,
        label: '待推送'
      },
      {
        value: 1,
        label: '已推送'
      },
      {
        value: 2,
        label: '运输中'
      },
      {
        value: 9,
        label: '已完成'
      },
      // {
      //   value: 3,
      //   label: '已接单'
      // },
      {
        value: 4,
        label: '待验车'
      },
      {
        value: 5,
        label: '已验车'
      },
      {
        value: 6,
        label: '已拒绝'
      },
      {
        value: 7,
        label: '已取消'
      },
      {
        value: 8,
        label: '待提车'
      }
    ]
  },

  mutations: {
    setChannelTypeEnum (state, ChannelTypeEnum) {
      state.ChannelTypeEnum = ChannelTypeEnum
    },
    setCustomerLabelType (state, CustomerLabelType) {
      state.CustomerType = JSON.parse(JSON.stringify(CustomerLabelType))
      CustomerLabelType.unshift({ label: '全部', value: '' })
      state.CustomerLabelType = CustomerLabelType
    },
    setDropPublicEnum (state, DropPublicEnum) {
      state.DropPublicEnum = DropPublicEnum
    },
    // setCustomerSourceEnum (state, customerSource) {
    //   state.customerSource = customerSource
    // },
    setIntentionEnum (state, IntentionEnum) {
      state.IntentionEnum = IntentionEnum
    },
    setFollowUpEnum (state, FollowUpEnum) {
      state.FollowUpEnum = FollowUpEnum
    },
    setCarrierEnum (state, CarrierEnum) {
      state.CarrierEnum = CarrierEnum
    },
    setLabel (state, Label) {
      state.Label = Label
    },
    setInvalidClueEnum (state, InvalidEnum) {
      state.InvalidClue = InvalidEnum
    }
  },
  actions: {
    getDataList ({ commit }) {
      channeltree().then(response => {
        commit('setChannelTypeEnum', response.data)
      })
      getLabelPage({ current: 1, size: 10000 }).then(response => {
        const CustomerLabel = []
        const DropPublic = []
        const Intention = []
        const FollowUp = []
        // const CustomerArr = []
        const InvalidArr = []
        const Carrier = []
        const LabelArr = []
        response.data.records.forEach(e => {
          if (e.labelType != null) {
            switch (e.labelType.value) {
              case 1:
                FollowUp.push({
                  label: e.name,
                  value: e.id
                })
                break
              case 2:
                CustomerLabel.push({
                  label: e.name,
                  value: e.id
                })
                break
              case 3:
                DropPublic.push({
                  label: e.name,
                  value: e.id
                })
                break
              case 4:
                Intention.push({
                  label: e.name,
                  value: e.id
                })
                break
              case 6:
                // CustomerArr.push({
                //   label: e.name,
                //   value: e.id
                // })
                break
              case 7:
                InvalidArr.push({
                  label: e.name,
                  value: e.id
                })
                break
              case 8:
                Carrier.push({
                  label: e.name,
                  value: e.id
                })
                break
              case 9:
                LabelArr.push({
                  label: e.name,
                  value: e.id
                })
                break
              default:
                break
            }
          }
        })
        setTimeout(() => {
          commit('setDropPublicEnum', DropPublic)
          commit('setIntentionEnum', Intention)
          commit('setFollowUpEnum', FollowUp)
          commit('setCarrierEnum', Carrier)
          commit('setLabel', LabelArr)
          commit('setCustomerLabelType', CustomerLabel)
          commit('setInvalidClueEnum', InvalidArr)
          // commit('setCustomerSourceEnum', CustomerArr)
        }, 300)
      })
    }
  }
}
export default enumAll
