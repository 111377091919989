<style lang="less" scoped>
@import "./BasicLayout.less";
</style>
<template>
  <div>
    <a-layout class="basic-layout">
      <div
        v-if="siderFixed"
        class="fixed-stuff"
        :style="{ width: collapsed ? '80px' : '200px', overflow: 'hidden' }"
      />
      <a-layout-sider
        v-model:collapsed="collapsed"
        :class="[
          'layout-sider',
          { 'layout-sider-dark': siderTheme === 'dark' },
          { 'layout-sider-light': siderTheme === 'light' },
          'layout-sider-side',
          { 'layout-sider-fixed': siderFixed },
        ]"
        :theme="siderTheme"
        width="200"
        collapsedWidth="80"
        :trigger="null"
        style="overflow: hidden"
        collapsible
      >
        <div
          class="layout-sider-logo"
          :class="siderTheme === 'dark' ? 'layout-sider-logo-dark' : ''"
        >
          <a href="/" target="_self" class="i-link i-link-color">
            <img v-show="!collapsed" src="@/assets/images/logo.png" key="max-logo" />
            <img v-show="collapsed" src="@/assets/images/logo-min.png" key="min-logo" />
          </a>
        </div>
        <!-- 主页导航栏 -->
        <div style="flex: 1 1 0%; overflow: hidden auto">
          <a-menu v-model:selectedKeys="selectedMenu" :theme="siderTheme" mode="inline">
            <template v-for="menu in menus">
              <a-sub-menu v-if="menu.children" :key="menu.name">
                <!--                <template #icon>-->
                <!--                  <ContactsOutlined v-if="menu.meta.icon==='carrier'" :style="{fontSize:'20px'}"/>-->
                <!--                  <ShopOutlined v-if="menu.meta.icon==='store'" :style="{fontSize:'20px'}"/>-->
                <!--                  <ClusterOutlined v-if="menu.meta.icon==='transportCapacity'" :style="{fontSize:'20px'}"/>-->
                <!--                  <icon-font :type="`hy-${menu.meta.icon}`" :style="{fontSize:'20px'}"/>-->
                <!--                </template>-->
                <template #icon>
                  <span style="color: #fff; font-size: 20px"
                    ><svg class="icon" aria-hidden="true">
                      <use :href="`#hy-${menu.meta.icon}`"></use></svg
                  ></span>
                </template>
                <template #title>
                  {{ $t(menu.meta.title) }}
                </template>
                <!-- <a-menu-item v-for="itemMenu in menu.children" :key="itemMenu.name" @click="routerMenu(itemMenu)">
                  <router-link :to="itemMenu.jump === true ? '/home' : itemMenu.path">{{ $t(itemMenu.meta.title)
                  }}</router-link>
                  </a-menu-item> -->
                <a-menu-item v-for="itemMenu in menu.children" :key="itemMenu.name">
                  <router-link :to="itemMenu.path">{{
                    $t(itemMenu.meta.title)
                  }}</router-link>
                </a-menu-item>
              </a-sub-menu>
              <a-menu-item v-if="!menu.children" :key="menu.name">
                <router-link :to="menu.path">{{ $t(menu.meta.title) }}</router-link>
              </a-menu-item>
            </template>
          </a-menu>
        </div>
      </a-layout-sider>
      <a-layout>
        <a-layout-header
          :class="[
            'layout-header',
            'layout-header-fix',
            { 'layout-header-color-dark': headerTheme === 'dark' },
            { 'layout-header-color-light': headerTheme === 'light' },
          ]"
          :style="{ width: collapsed ? 'calc(100% - 80px)' : 'calc(100% - 200px)' }"
        >
          <span class="layout-header-trigger" @click="() => (collapsed = !collapsed)">
            <menu-unfold-outlined v-if="collapsed" />
            <menu-fold-outlined v-else />
          </span>
          <!--          <a-breadcrumb class="layout-header-breadcrumb">-->
          <!--            <a-breadcrumb-item>User</a-breadcrumb-item>-->
          <!--            <a-breadcrumb-item>Bill</a-breadcrumb-item>-->
          <!--          </a-breadcrumb>-->
          <div class="layout-header-right" style="display: flex">
            <!-- <div style="font-weight:bold">
              <span>快捷键：</span>
              <span style="margin-right:10px">[调度/门店操作—H+Y]</span>
              <span style="margin-right:10px">[库存操作—H+K]</span>
              <span style="margin-right:10px">[GPS位置查询—H+G]</span>
              <span>[快速查车—Alt+V]</span>
            </div> -->
            <div @click="refresh"> <a-button type="primary" size="small">刷新</a-button></div>
            <div class="pos-rel cur-p" @click="openNews" style="margin-right: 5px;min-width:20px;margin-left:15px">
              <div class="pos-abs" style="margin-top: -2px;margin-left:-4px"><img style="width:22px;height:22px" src="@/assets/images/repository/news.png" alt=""></div>
              <div class="tipsNumBox flex al-center ju-center">   <a-badge :count="countNum" /></div>
              </div>
            <full-screen v-model:value="fullscreen" class="layout-header-trigger" />
            <a-dropdown v-if="currentUser && currentUser.name">
              <span class="layout-header-trigger">
                <a-avatar
                  class="layout-header-avatar"
                  src="https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png"
                />
                <span class="layout-header-name">{{ currentUser.name }}</span>
              </span>
              <template v-slot:overlay>
                <a-menu :selected-keys="[]">
                  <a-menu-item key="center" @click="handleToCenter" disabled>
                    <UserOutlined />
                    {{ $t("menu.account.center") }}
                  </a-menu-item>
                  <a-menu-item key="settings" @click="handleToSettings" disabled>
                    <SettingOutlined />
                    {{ $t("menu.account.settings") }}
                  </a-menu-item>
                  <a-menu-item key="settings" @click="handleChangePass">
                    <KeyOutlined />
                    修改密码
                  </a-menu-item>
                  <a-menu-divider />
                  <a-menu-item @click="handleLogout">
                    <ExportOutlined />
                    {{ $t("menu.account.logout") }}
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
            <template v-if="currentUser.name.indexOf('保险') === -1">
              <SelectLang class="layout-header-trigger" />
            </template>
            
          </div>
        </a-layout-header>
        <a-layout-content
          class="basic-layout-content basic-layout-content-fix-with-header"
        >
          <MenuTabs
            ref="closeAllTab"
            :pageTabs="pageTabs"
            :style="{ width: collapsed ? 'calc(100% - 80px)' : 'calc(100% - 200px)' }"
          />
          <div class="ant-page-container">
            <Suspense>
              <template #default>
                <router-view v-slot="{ Component }">
                  <keep-alive>
                    <component
                      :is="Component"
                      :key="router.currentRoute.value.meta.name"
                      v-if="router.currentRoute.value.meta.keepAlive === true"
                    />
                  </keep-alive>
                  <component
                    :is="Component"
                    :key="router.currentRoute.value.meta.name"
                    v-if="router.currentRoute.value.meta.keepAlive === false"
                  />
                </router-view>
              </template>
              <template #fallback> Loading... </template>
            </Suspense>
          </div>
        </a-layout-content>
        <!-- <a-layout-footer style="text-align: center">
          HuaYue System ©2021 Created by HuaYue IT
                  </a-layout-footer> -->
      </a-layout>
    </a-layout>
    <ChangePass v-if="visible" v-model:value="visible" />
    <VinNo
      :vinNoIndex="vinNoIndex"
      :showDOM="vinNoShow"
      @statusShow="vinnoStatusChange"
    />
    <Transport
      :transportIndex="transportIndex"
      :showDOM="transportShow"
      @statusShow="transportStatusChange"
    />
    <ShopOperate
      :shopOperateIndex="shopOperateIndex"
      :showDOM="shopOperateShow"
      @statusShow="shopStatusChange"
    />
    <!-- 消息 -->
    <News v-if="newsVisible"  @change="newChange" v-model:visible="newsVisible" />
    <RepositoryMadal v-model:showDOM="repositoryMadalShow" />
    <a-modal
      v-model:visible="detailDataShow"
      width="80%"
      title="车辆详情"
      :destroyOnClose="true"
    >
      <TotalDetail
        v-if="detailData"
        :element="{}"
        :orderId="detailData.orderId"
        :orderVehicleId="detailData.orderVehicleId"
        :index="0"
      />
    </a-modal>
    <!-- 位置查询 -->
    <GPS :GPSindex="GPSindex" ref="gpsSearchRef" />
    <!-- 营销中台 -->
    <MarketingConsole v-model:visible="marketingVisible" v-if="marketingVisible"></MarketingConsole>
    <!-- 新版运力 -->
    <NewTransport v-model:visible="newTransVisible" v-if="newTransVisible"></NewTransport>
     <!-- 指标看板 -->
    <IndicatorModule v-if="IndicatorVisible"  v-model:visible="IndicatorVisible" ></IndicatorModule>
    <a-modal v-model:visible="cesi" title="标题">
      <Cesi></Cesi>
    </a-modal>
  </div>
</template>
<script>
import { toRefs, onMounted, computed, watch, reactive, ref, h } from 'vue'
import TotalDetail from '@/views/transportCapacity/transportUse/detail/totalDetail.vue'
import GPS from '@/views/transportCapacity/transportUse/comon/gps.vue'
import RepositoryMadal from '@/views/repository/categoryConfiguration/repositoryMadal.vue'
// import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import storage from 'store'
import { list as orgList } from '@/api/system/organzition'
import Cookies from 'js-cookie'
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  SettingOutlined,
  ExportOutlined,
  DownOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CloseOutlined,
  CloseCircleOutlined,
  KeyOutlined,
  ShopOutlined,
  ContactsOutlined,
  ClusterOutlined,
  DashboardOutlined,
  createFromIconfontCN,
  
} from '@ant-design/icons-vue'
import { Modal, notification, Button } from 'ant-design-vue'
import SelectLang from '@/components/SelectLang'
import ChangePass from '@/views/components/change-pass'
import FullScreen from './components/Fullscreen'
import MenuTabs from './components/MenuTabs'
import VinNo from '@/views/components/vinNo'
import Transport from '@/views/components/transport'
import ShopOperate from '@/views/components/shopOperate'
import News from './components/news'
import {msgUnread} from '@/api/news/news'
import { carBrandTree } from '@/api/system/setting'
import jsonlint from '@/utils/jsonlint'
import NewTransport from './components/newTransport' 
import MarketingConsole from './components/marketingConsole' 
import IndicatorModule from '@/views/components/IndicatorModule'
import Cesi from './cesi.vue'
const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_2734322_olh9vocrojb.js'
})
export default {
  setup () {
    const marketingVisible = ref(false)
    const newTransVisible = ref(false)
    let closeAllTab = ref(null)
    // const { t } = useI18n()
    const store = useStore()
    const data = { content: { title: '', content: '', time: '' } }
    const router = useRouter()
    const menus = computed(() => store.getters.asyncRouters)
    const selectedMenu = computed(() => [router.currentRoute.value.name])
    const pageTabs = computed(() => store.getters.tabs)
    const gpsSearchRef = ref(null)
    let systemDomain = ''
   const newsVisible = ref(false)
    const shallowState = reactive({
      cesi:false,
      detailDataShow: false,
      siderFixed: true,
      vinNoIndex: 1000,
      transportIndex: 1000,
      shopOperateIndex: 1000,
      GPSindex: 1000,
      siderTheme: 'dark',
      routerPath: '',
      headerTheme: 'light',
      collapsed: false,
      fullscreen: false,
      orderIdList: [],
      orderVehicleIdList: [],
      detailData: { orderId: '', orderVehicleId: '' },
      currentUser: { name: store.getters.userName },
      visible: false,
      vinNoShow: false,
      transportShow: false,
      shopOperateShow: false,
      vinNo: ref(null),
      transport: ref(null),
      repositoryMadalShow:false,
      IndicatorVisible:false
    })
    const countNum = ref(0)
    const routerMenu = (menu) => {
      if (menu.jump) {
        shallowState.routerPath = menu.path
        // Cookies.remove('accessToken')
        // Cookies.set('accessToken', storage.get('accessToken'), '.56pai.cn')
        const H = process.env.NODE_ENV === 'prod' ? 'https://' : 'http://'
        systemDomain = store.getters.uat.filter(
          (item) => item.systemType.value === menu.systemType
        )[0].systemDomain
        window.open(H + systemDomain + '/#' + menu.path, '_parent')
      }
    }
    //刷新
    const refresh = () => {
      // shallowState.cesi = true
   
      window.location.replace(window.location.href)
          window.location.reload(true);  
    }
    const openNews = () => {
      newsVisible.value = true
      reqMsgUnread() 
    }
    const newChange = () => {
      reqMsgUnread()
    }
     
    const reqMsgUnread = () => {
      msgUnread({}).then( res => {
        //  console.log('xiaoxi',res);
         if(res.code === 10000){
          countNum.value = res.data
         }
      })
    }
    onMounted(() => {
      const index = menus.value.findIndex((item) => item.name === 'crmManagement')
      const i = menus.value.findIndex((item) => item.name === 'dataCenter')
      reqMsgUnread()
      // menus.value[index].children.unshift({
      //   path: '/crmManagement/relevance',
      //   name: 'relevance',
      //   component: () => import('@/views/crmManagement/relevance'),
      //   meta: { title: 'menu.crmManagement.relevance', keepAlive: false }
      // });
      // console.log(store);
      // menus.value.push(
      //   {
      //     path: '/statistics',
      //     name: 'statisticsData',
      //     component: () => import('@/layouts/BasicLayout.vue'),
      //     meta: { title: 'menu.statisticsData', icon: 'jichupaas', keepAlive: false },
      //     children: []
      //   })
      // setTimeout(() => {
      //   menus.value[i].children.unshift()
      // }, 500)
      notification.config({
        duration: 3
      })
      loadData()
      closeAllTab = closeAllTab.value.closeAllTab
      setTimeout(() => {
        websocket()
      }, 1000)
      store.commit('setBreadCrumb', router.currentRoute.value)
      // systemDomain = store.getters.uat.filter(item => item.systemType.value !== 2)[0].systemDomain // cookies跳转 B项目营销为2 api/login.js登录接口改为2
      // vinNoIndex: 1000,
      // transportIndex: 1000,
      // shopOperateIndex: 1000,
      // GPSindex: 1000,
      if (shallowState.currentUser.name.indexOf('保险') === -1) {
        let H = false // 72
        let N = false // 78
        let D =  false //68
        let A = false //65
        // 全局热键
        document.onkeydown = function (e) {
          if (e.altKey && e.keyCode === 86) {
            // alt+v
            shallowState.vinNoShow = true
            // shallowState.vinNoIndex = shallowState.transportIndex + shallowState.shopOperateIndex + shallowState.GPSindex
          }
        
          if (e.keyCode === 72) H = true // H
          if (e.keyCode === 89 && H === true) {
            // H+Y
            shallowState.transportShow = true
            shallowState.shopOperateShow = false
            shallowState.repositoryMadalShow = false
            newTransVisible.value = false
            shallowState.IndicatorVisible = false
            marketingVisible.value = false
            // shallowState.transportIndex = shallowState.vinNoIndex + shallowState.shopOperateIndex + shallowState.GPSindex
          }
          if (e.keyCode === 75 && H === true) {
            // H+K
            shallowState.shopOperateShow = true
            shallowState.transportShow = false
            shallowState.repositoryMadalShow = false
            newTransVisible.value = false
            shallowState.IndicatorVisible = false
            marketingVisible.value = false
            // shallowState.shopOperateIndex = shallowState.transportIndex + shallowState.vinNoShow + shallowState.GPSindex
          }
          if (e.keyCode === 71 && H === true) {
            // H+G
            gpsSearchRef.value.onSwitch(true)
            // shallowState.GPSindex = shallowState.transportIndex + shallowState.shopOperateIndex + shallowState.vinNoShow
          }
          if(e.keyCode  === 78) N = true
          if(e.keyCode === 78 && H === true) {
            shallowState.repositoryMadalShow = true
            shallowState.shopOperateShow = false
            shallowState.transportShow = false
            newTransVisible.value = false
            shallowState.IndicatorVisible = false
            marketingVisible.value = false
          }
          if(e.keyCode === 72 && N === true) {
            shallowState.repositoryMadalShow = true
            shallowState.shopOperateShow = false
            shallowState.transportShow = false
            newTransVisible.value = false
            shallowState.IndicatorVisible = false
            marketingVisible.value = false
          }
       
          if(e.keyCode === 68)  D = true
          if (e.keyCode === 67 && D) {
            // d+c
            newTransVisible.value = true
            shallowState.repositoryMadalShow = false
            shallowState.shopOperateShow = false
            shallowState.transportShow = false
            shallowState.IndicatorVisible = false
            marketingVisible.value = false
          }
          if(e.keyCode === 70 && D){
            shallowState.IndicatorVisible = true
            newTransVisible.value = false
            shallowState.repositoryMadalShow = false
            shallowState.shopOperateShow = false
            shallowState.transportShow = false
            marketingVisible.value = false
          }
          if(e.keyCode === 65) A = true
          if(e.keyCode === 74 && A){
            // AJ
            marketingVisible.value = true
            shallowState.IndicatorVisible = false
            newTransVisible.value = false
            shallowState.repositoryMadalShow = false
            shallowState.shopOperateShow = false
            shallowState.transportShow = false
          }
          
        }
        document.onkeyup = function (e) {
          // 键盘弹起时触发
          if (e.keyCode === 72) H = false
          if(e.keyCode  === 78) N = false
          if(e.keyCode  === 68) D = false
          if(e.keyCode  === 65) A = false
        }
      }
    })
    const loadData = () => {
      orgList({ orgType: 1 }).then((res) => {
        if (res.code === 10000) store.commit('ORG_TYPE_LIST', res.data)
      })
      orgList({}).then((res) => {
        if (res.code === 10000) store.commit('ORG_LIST', res.data)
      })
      carBrandTree({}).then((res) => {
        if (res.code === 10000) store.commit('CAR_BRAND', res.data)
      })
    }
    const cachePage = computed(() => store.state.app.cachePage)
    const handleToCenter = () => {
      // Todo
    }
    const handleChangePass = () => {
      shallowState.visible = true
    }
    const handleToSettings = () => {}
    const openNotification = () => {
      const key = `open${Date.now()}`
      notification.open({
        key,
        message: data.content.title,
        description: (
          h('div', [
           h('p',data.content.content),
           h('p',data.content.time)
       ])
        ),
        placement: 'topLeft',
        btn: h(
          Button,
          {
            type: 'primary',
            size: 'small',
            onClick: () => {
              notification.close(key)
              if (
                shallowState.detailData.orderId &&
                shallowState.detailData.orderId !== ''
              ) {
                shallowState.detailData = {
                  orderVehicleId:
                    shallowState.orderVehicleIdList[
                      shallowState.orderVehicleIdList.length - 1
                    ],
                  orderId: shallowState.orderIdList[shallowState.orderIdList.length - 1]
                }
                if (shallowState.detailDataShow === false) {
                  shallowState.detailDataShow = true
                } else {
                  shallowState.detailDataShow = false
                  setTimeout(() => {
                    shallowState.detailDataShow = true
                  }, 10)
                }
                setTimeout(() => {
                  shallowState.orderVehicleIdList.splice(
                    shallowState.orderVehicleIdList.length - 1,
                    1
                  )
                  shallowState.orderIdList.splice(shallowState.orderIdList.length - 1, 1)
                }, 10)
              }
            }
          }
          // shallowState.detailData.orderId !== '' ? '查看' : '关闭'
        )
      })
      setTimeout(() => {
        const DOMList = document.querySelectorAll('.ant-notification-notice')
        DOMList.forEach((item) => {
          item.style.width = '384px'
          item.style.position = 'absolute'
          item.style.left = '200%'
          if (shallowState.detailData.orderId !== '') { item.style.background = 'rgba(255,255,193)' }
        })
      }, 10)
    }
    const websocket = () => {
      store.state.user.ws.onmessage = (evt) => {
        const websocketData = jsonlint.parse(evt.data)
        if (websocketData.msgType !== 'ACK') {
          shallowState.orderIdList.push(websocketData.orderId)
          shallowState.orderVehicleIdList.push(websocketData.orderVehicleId)
          shallowState.detailData = {
            orderId: websocketData.orderId,
            orderVehicleId: websocketData.orderVehicleId
          }
          data.content.title = '新消息'
          data.content.content = websocketData.content
          openNotification()
          timeOutMsg()
        }
      }
    }
    watch(
      router.currentRoute,
      (newRoute, oldRoute) => {
        const { name, query, params, meta } = newRoute
        store.commit('ADD_TAB', { name, query, params, meta })
        store.commit('setBreadCrumb', newRoute)
        // console.log(cachePage) // store.state.app.
      },
      { deep: true }
    )
    let flag = false
    const timeOutMsg = () => {
      if(flag) return
       flag = true
       let time = 0
       let timeOut = setTimeout(() => {
          reqMsgUnread()
          clearTimeout(timeOut)
        },5000)
       let timeOut2 = setInterval(() => {
           time++
           if(time>29){
               flag = false
               clearInterval(timeOut2)
               time = 0
           }
        },1000)
    }
    const handleLogout = (e) => {
      Modal.confirm({
        title: '信息',
        content: '你确定要退出吗',
        onOk: async () => {
          return store.dispatch('Logout').then(() => {
            storage.remove('accessToken')
            Cookies.remove('accessToken')
            router.push({ name: 'login' })
            closeAllTab()
            location.reload()
          })
        },
        onCancel () {}
      })
    }
    const vinnoStatusChange = (e) => {
      if (e !== undefined) {
        shallowState.vinNoShow = e
      }
    }
    const transportStatusChange = (e) => {
      if (e !== undefined) {
        shallowState.transportShow = e
      }
    }
    const shopStatusChange = (e) => {
      if (e !== undefined) {
        shallowState.shopOperateShow = e
      }
    }
    return {
      ...toRefs(shallowState),
      closeAllTab, // ref事件传递return
      gpsSearchRef,
      menus,
      cachePage,
      selectedMenu,
      IconFont,
      router,
      vinnoStatusChange,
      shopStatusChange,
      transportStatusChange,
      pageTabs,
      routerMenu,
      handleToCenter,
      handleLogout,
      handleChangePass,
      handleToSettings,
      refresh,
      newsVisible,
      openNews,
      countNum,
      newChange,
      newTransVisible,
      marketingVisible
    }
  },
  components: {
    GPS,
    TotalDetail,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    SettingOutlined,
    ExportOutlined,
    DownOutlined,
    SelectLang,
    FullScreen,
    MenuTabs,
    ArrowLeftOutlined,
    ArrowRightOutlined,
    CloseOutlined,
    CloseCircleOutlined,
    DashboardOutlined,
    KeyOutlined,
    ClusterOutlined,
    ShopOutlined,
    ContactsOutlined,
    ChangePass,
    IconFont,
    VinNo,
    Transport,
    ShopOperate,
    RepositoryMadal,
    News,
    NewTransport,
    IndicatorModule,
    MarketingConsole,
    Cesi
  }
}
</script>
