<template>
  <div class="flex ju-between">
    <div class="box1">
      <div v-for="(item,index) in list1" :key="item.id">
        <div :class="idx === index ? 'itenNanme' : ''">{{ item.name }}</div>
      </div>
    </div>
    <div>
      <a-button @click="add">交换</a-button>
    </div>
    <div class="box2">
      <div v-for="item in list2" :key="item.id">
        <div>{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
const list1 = ref([
  { name: '1', age: 1 },
  { name: '2', age: 2 },
])
const list2 = ref([
  { name: 'a', age: 3 },
  { name: 'b', age: 4 }
])
const idx = ref(null)

const add = () => {
  idx.value = 0
} 
</script>

<style lang="less" scoped>
.box1 {
  border: 1px solid red;
  width: 120px;
  height: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.box2 {
  border: 1px solid blue;
  width: 120px;
  height: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.itenNanme {
  position: relative;
  animation: bimg1 3s  cubic-bezier(0,0,1,1),bimg2 3s  cubic-bezier(0,1,0,1);
  }
@keyframes name {
  0% {
    transform: rotateZ(30deg) translateX(50px);
  }
  25% {
    transform: rotateZ(30deg) translateX(100px);
  }
  50% {
    transform: rotateZ(30deg) translateX(150px);
  }
  75% {
    transform: rotateZ(30deg) translateX(200px);
  }
  100% {
    transform: rotateZ(30deg) translateX(330px);
  }
}


@keyframes bimg1 {
      0% {top: 0;}
      100% {top: 150px;}
}
@keyframes bimg2 {
  0%  {left: 0;}
  100% {left: 350px;}
}

</style>