<template>
  <div>
    <a-modal :visible="visible" title="待验车" centered footer="" @cancel="cancel" width="80%" :bodyStyle="{ padding:'10px',background:'#EEEEEE'}">
      <div class="statusBox flex">
        <div v-for="item in tabstatus" :key="item.id" class="item cur-p" @click="changeBtn(item)" :class="indexKey === item.colorType ?'changeItem':''">
          {{ item.name }}-{{ item.total }}
        </div>
      </div>
      <SearchList :loading="loading" @searchAdd="searchAdd" @resetBtn="resetBtn" :iptData="iptData"></SearchList>
      <div class="m-t1 tableBox">
        <div class="sys-screenTitle">当前筛选出&nbsp;<a >{{ data.length }}</a>&nbsp;条</div>
        <a-table ref="tableRef" :dataSource="dataSource" size="small" :columns="columns" bordered :pagination="false" :scroll="{y:500,x:2000}" :loading="loading">
          <template #sort="{ index }">
            {{index +1 }}
          </template>
          <template #vinNo="{ record }">
            <div class="flex al-center ju-center">
              <a-popover>
                <template #content>
                  {{ record.taskCarrierName }}
                </template>
                <div class="isExpatriate cur-p" v-if="record.taskId">
                  派
                </div>
              </a-popover>
              {{ record.vinNo }}
              <a-typography-paragraph :copyable="{ text: record.vinNo }">
              </a-typography-paragraph>
            </div>
            <div>
              {{ record.brand }}{{ record.model }}
            </div>
          </template>
          <template #startAddress="{ record }">
            {{ record.startAddress}}
            <div>
              {{ record.endAddress }}
            </div>
          </template>
          <template #scheduleCarrierName="{ record }">
            <div>  {{ record.arranger	 }}</div>
          {{ record.scheduleTime }}
          </template>
          <template #add="{ record }">
            <a @click="model(record)">操作</a>
          </template>
          <template #deliveryCard="{ record }">
            <div class="flex" v-if="record.pic?.value === 1">
              <div class="picInfoBox">照片：</div>
              <a @click="lookVehicleImgs(record)">查看</a>
            </div>
            <div style="text-align: left;" v-if="record.address?.value === 1">
              地址：<a @click="lookVehicleGps(record)">查看</a>
              ({{ record.gpsCity}}{{ record.gpsTime}})
            </div>
            <div class="flex" v-if="record.deliveryCard?.value === 1">
              <div class="picInfoBox">交车条：</div>
              <a @click="lookDeliveryCard(record)">查看</a>
            </div>
            <div v-if="record.taskStartTime" style="text-align: left;">
              发运时间：{{ record.taskStartTime}}
            </div>
          </template>
          <template #dispatchRemark="{ record }">
            <div style="display:flex" @mousemove="remarkId = record.dispatchId">
              <div class="remark-content">
                <a-tooltip color="#fff">
                  <template #title>
                    <span style="color:#000">{{ record.dispatchRemark }}</span>
                  </template>
                  <span>{{ record.dispatchRemark }}</span>
                </a-tooltip>
                <a v-show="!record.dispatchRemark" @click="openDisRemarks(record)">新增调度备注</a>
              </div>
              <div style="display:flex;align-items:center;min-width:30px" class="m-l1">
                <a v-show="record.dispatchRemark" @click="openDisRemarks(record)">记录</a>
              </div>
            </div>
          </template>
          <template #custom="{ record }">
             <a @click="handleCope(record)">小程序码</a>
            <a class="m-l1" @click="driverOpen(record)">司机信息</a>
          </template>
        </a-table>
      </div>
      <!-- 小车详情 -->
      <SmallVehDetails ref="smallVehDetailsRef"></SmallVehDetails>
      <!-- 新增/编辑调度备注 -->
      <SchedulingLogs @setRemaekOk="setRemaekOk" :remarkId="remarkId" :isOverTime="isOverTime" v-if="remarkShow" v-model:visible="remarkShow" :orderVehicleId="logsVehicleId" />
       <!-- 车辆照片 -->
       <VehicleImg ref="vehicleImgRefs" />
      <!-- 在途位置 -->
      <VehicleGps ref="vehicleGpss" />
      <!-- 交车条 -->
      <PictureViewer v-model:visible="visibleLookDeliver" :lookImgID="lookImgID"></PictureViewer>
      <!-- 小程序码 -->
      <a-modal v-model:visible="QRCshow" title="小程序码" footer="">
        <div>
          <div>
            <div>车架号：<a-tag color="green">{{ QRCdata.vinNo }}</a-tag></div>
            <div>线路：<span style="color:#f00"><span v-if="QRCdata.placeInAddress">{{ QRCdata.placeInAddress }}-</span>{{QRCdata.startAddress  }}</span>
            </div>
            <img v-show="!QRCloading" class="img" :src="QRCurl" style="width:100%;height:100%" />
            <div style="text-align: center"><a-spin v-show="QRCloading" size="large" /></div>
          </div>
        </div>
      </a-modal>
      <!-- 司机信息 -->
      <a-modal v-model:visible="evidenceShow" title="司机信息" footer="" width="350px">
        <div class="evidenceDOM">
          <p class="font-color2">司机姓名：{{ QRCdata?.inspectionDriverName }}</p>
          <p class="font-color2"></p>
          <p class="font-color2">联系电话：{{ QRCdata?.inspectionDriverMobile }}</p>
          <p class="font-color2"></p>
          <p class="font-color2">身份证号：{{ QRCdata?.inspectionDriverIdNumber }}</p>
        </div>
      </a-modal>
    </a-modal>
  </div>
</template>
  
  <script setup>
import { onMounted, ref, reactive, nextTick } from 'vue'
import { addressTran, IfNull } from '@/utils/util'
import { QRCurl as getQRCurl } from '@/api/transport/inspection'
import SearchList from '@/components/searchList'
import { inspectionTaskNoInspect } from '@/api/newTransport/newTransport'
import SchedulingLogs from '@/views/components/transport/smallVehicleOperate/schedulingLogs.vue'
import SmallVehDetails from '@/components/smallVehDetails'
import { useStore } from 'vuex'
import { cloneDeep } from 'lodash-es'
import VehicleImg from '@/views/components/vehicleImg'
import VehicleGps from '@/views/order/positionInfo/carGps.vue'
import PictureViewer from '@/components/PictureViewer'
import { taskDeliveryPic } from '@/api/transport/transport'

const store = useStore()
const emit = defineEmits(['update:visible'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  info: {
    type: Object,
    default: {}
  },
  colorIdx: {
    type: Number,
    default: null
  },
  vehicleNumType:{
    type: Number,
    default: 0
  }
})

const tableRef = ref(null)
const lookImgID = ref([])
const visibleLookDeliver = ref(null)
const vehicleGpss = ref(null)
const vehicleImgRefs = ref(null)
const loading = ref(false)
const indexKey = ref(null)
const dataSource = ref([])
const data = ref([])
const smallVehDetailsRef = ref(null)
const searchMsg = ref({
  vehicleNum:1
})
const remarkId = ref('')
const isOverTime = reactive({
  isOverTime: '',
  transportType: '',
  taskType: '',
})
const logsVehicleId = ref('')
const remarkShow = ref(false)
const QRCurl = ref('')
const QRCshow = ref(false)
const QRCdata = ref({})
const QRCloading = ref(false)
const evidenceShow = ref(false)
const pageSize = ref(50)

// 查看交车条
const lookDeliveryCard = (record) => {
  taskDeliveryPic(record.orderVehicleId, {
    taskId: record.taskId
  }).then(res => {
    if (res.code !== 10000) return
    lookImgID.value = res.data.map(item => item.fileId)
    visibleLookDeliver.value = true
  })
}
// 查看视频图片
const lookVehicleImgs = (record) => {
  setTimeout(() => {
    vehicleImgRefs.value.vehicleImg(record.orderVehicleId, record.vinNo)
  }, 50)
}
// 查看位置
const lookVehicleGps = (record) => {
  setTimeout(() => {
    vehicleGpss.value.onvisible(true)
    vehicleGpss.value.onorderVehicleId(record.orderVehicleId)
  }, 50)
}
//司机信息
const driverOpen = (e) => {
  evidenceShow.value = !evidenceShow.value
  QRCdata.value = e
}
//小程序
const handleCope = (e) => {
  QRCshow.value = !QRCshow.value
  QRCdata.value = e
  if (QRCshow.value === true) {
    QRCloading.value = true
    const wXprogramQRcode = { scene: e.orderVehicleId, type: 1 }
    getQRCurl(wXprogramQRcode)
      .then(res => {
        if (res.code === 10000) {
          QRCurl.value = 'data:image/png;base64,' + res.data
          QRCloading.value = false
        }
      }).catch(err => console.log(err))
  }
}
const openDisRemarks = (record) => {
  isOverTime.isOverTime = record.isOverTime.value
  isOverTime.transportType = record.transportType.value
  isOverTime.taskType = record.taskType
  remarkShow.value = true
  logsVehicleId.value = record.orderVehicleId
}
const model = e => {
  smallVehDetailsRef.value.detail = e
  smallVehDetailsRef.value.windowShow = true
  setTimeout(() => {
    smallVehDetailsRef.value.onOk()
  }, 100)
}
const searchAdd = (e) => {
  let msg = cloneDeep(e)
  msg.startAddress = addressTran(msg.startAddress)
  msg.endAddress = addressTran(msg.endAddress)
  searchMsg.value = msg
  reqInspectionTaskNoInspect()
}
const setRemaekOk = (str, label) => {
  if (IfNull(str)) {
    data.value.forEach(item => {
      if (item.dispatchId == remarkId.value) {
        item.dispatchRemark = str;
      }
    })
  }
  if(label){
    data.value.forEach(item => {
      if (item.dispatchId == remarkId.value) {
        item.labelName = label;
      }
    })
  }
}
const resetBtn = () => {
  searchMsg.value = {
    vehicleNum: props.vehicleNumType
  }
  reqInspectionTaskNoInspect()
}
const cancel = () => {
  emit('update:visible', false)
}
const changeBtn = (e) => {
  indexKey.value = e.colorType
  reqInspectionTaskNoInspect()
}
const reqInspectionTaskNoInspect = () => {
  loading.value = true
  ResetScroll()
  inspectionTaskNoInspect({
    ...searchMsg.value,
    subType: indexKey.value
  }).then(res => {
    if (res.code !== 10000) return
    data.value = res.data ? res.data : []
    dataSource.value = data.value.slice(0, pageSize.value)
  }).finally(() => {
    loading.value = false
  })
}
const ResetScroll = () => {
  pageSize.value = 50
  dataSource.value = []
}
onMounted(() => {
  if (props.vehicleNumType === 2) {
    searchMsg.value = {
      vehicleNum: props.vehicleNumType
    },
    iptData.value.forEach(item => {
      if(item.prop === 'vehicleNum'){
        item.value = props.vehicleNumType
      }
    })
  }
  if (props.info) {
    tabstatus.value[0].total = props.info.num
    if(props.info.subItemList){
    props.info.subItemList.forEach((opt) => {
      let msg = {
        name: '验-' + opt.title,
        total: opt.num,
        colorType: opt.colorType
      }
      tabstatus.value.push(msg)
    })
  }
  }
  indexKey.value = props.colorIdx;
  reqInspectionTaskNoInspect()
  nextTick(() => {
    const tableBody = tableRef.value.$el.querySelector('.ant-table-body');
    tableBody.addEventListener('scroll', (event) => {
      if (dataSource.value.length === 0) return
      if (pageSize.value > data.value.length) return
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      if (scrollTop + clientHeight >= scrollHeight - 100) {
        // 滚动条触底，执行加载数据的操作
        pageSize.value += 50
        dataSource.value = data.value.slice(0, pageSize.value)
        // 这里可以调用你的加载数据函数
      }
    });
  })
})

const tabstatus = ref([
  {
    name: '全部',
    id: 1,
    total: 0,
    colorType: null
  },

])
const iptData = ref([
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "startAddress",
    width: 200,
    changeOn: true
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "endAddress",
    width: 200,
    changeOn: true
  },
  {
    type: "select",
    placeholder: "是否外派",
    value: null,
    prop: "isTask",
    width: 200,
    opt: [
      {
        name: '是',
        id: 1
      },
      {
        name: '否',
        id: 0
      }
    ]
  },
  {
    type: "select",
    placeholder: "标签类型",
    value: null,
    prop: "labelId",
    width: 200,
    opt: store.state.app.labelList
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 200,
  },
  {
    type: "input",
    placeholder: "业务员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "安排人",
    value: null,
    prop: "inspectionOperator",
    width: 200,
  },
  {
    type: "input",
    placeholder: "承运商",
    value: null,
    prop: "inspectionCarrierName",
    width: 200,
  },
  {
    type: "input",
    placeholder: "调度备注",
    value: null,
    prop: "dispatchRemark",
    width: 200,
  },
  {
    type: "input",
    placeholder: "车牌号",
    value: null,
    prop: "vinNo",
    width: 200,
  },
  {
    type: "select",
    placeholder: "台量类型",
    value: 1,
    prop: "vehicleNum",
    width: 200,
    opt:[
      {
      id: 1,
      name: '散车'
      },
      {
      id: 2,
      name: '批量车'
      },
    ]
  },
])
const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    align: 'center',
    width: 50,
    slots: {
      customRender: 'sort'
    },
    fixed: 'left'
  },
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    width: '80px',
    slots: {
      customRender: 'add'
    },
    fixed: 'left'
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    align: 'center',
    slots: { customRender: 'vinNo' },
    fixed: 'left'
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '调度备注',
    dataIndex: 'dispatchRemark',
    align: 'center',
    slots: {
      customRender: 'dispatchRemark'
    },
    width: 150
  },
  {
    title: '起-终',
    dataIndex: 'startAddress',
    slots: {
      customRender: 'startAddress'
    },
    align: 'center',
    width: 200
  },
 
  {
    title: '业务员',
    dataIndex: 'salesman',
    align: 'center',
    width: 80
  },
  {
    title: '确认运输时间',
    dataIndex: 'confirmTime',
    align: 'center',
  },
  {
    title: '预约时间',
    dataIndex: 'pickAppointTime',
    align: 'center',
  },
  {
    title: '安排信息',
    dataIndex: 'scheduleCarrierName',
    align: 'center',
    slots: {
      customRender: 'scheduleCarrierName'
    }
  },
  {
    title: '承运商',
    dataIndex: 'inspectionCarrierName',
    align: 'center',
  },
  {
    title: '任务信息',
    dataIndex: 'deliveryCard',
    align: 'center',
    slots: {
      customRender: 'deliveryCard'
    },
    width: 150
  },
 
  {
    title: '照片数量',
    dataIndex: 'picNum',
    align: 'center',
    width: 80
  },

  {
    title: '标签',
    dataIndex: 'labelName',
    align: 'center',
    width: 100
  },
  {
    title: '超时信息',
    dataIndex: 'overTimeTip',
    align: 'center',
    width:100
  },
  {
    title: '台量类型',
    dataIndex: 'vehicleNum.label',
    align: 'center',
    width: 80
  },
  {
    title: '',
    dataIndex: 'custom',
    align: 'center',
    slots: {
      customRender: 'custom'
    },
    width: 150,
    fixed:'right'
  },

])
  </script>
  
  <style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}

.statusBox {
  border-bottom: 1px solid #eee;
  background: #fff;
}

.item {
  padding: 10px;
  border-bottom: 4px solid transparent;
  margin-right: 40px;
  font-size: 16px;
}
.changeItem {
  border-bottom: 4px solid #0066cc;
  color: #0066cc;
}
.remark-content {
  width: 80%;
  max-height: 80px;
  overflow: hidden;
  text-align: left;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.isExpatriate {
  min-width: 16px;
  height: 16px;
  background-color: rgba(57, 106, 252, 1);
  border-radius: 2px;
  font-size: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}
.ant-typography {
  margin: 0;
}
</style>